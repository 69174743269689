import {LOCALE_UPDATE} from '../_base/actions';

const INIT_STATE = {
  locale: {locale: 'en', lang:'en', des:'English'}
}

export default (state = INIT_STATE, action) => {
  switch(action.type){
    case LOCALE_UPDATE:
      return action.payload;
    default:
      return state;
  }
}