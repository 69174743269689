/*REACT*/
import React, { Component } from 'react';

/*BASE*/
import _ from 'lodash';

/*CSS*/
import './ZTablizar.css';

import ZTBodyTr from './ZTBodyTr';

class ZTBody extends Component {

  constructor(){
    super();
    this.state = {}
  }

  componentDidMount(){
    this.setState((state, props) => ({
      ...props
    }));
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps !== this.props){
      this.componentDidMount();
    }
  }

  renderRow = () => {
    let {schema, sortMode, sortField, pageSize, currentPage, minRow} = this.state;
    let dataRows = [], emptyRows = [];
    let nowRow = 0;
    let data = [];
    //sort
    data = this.state.data;
    if(schema){
      let mode;
      if(sortMode === -1) mode = 'desc';
      else mode = 'asc';

      if(schema[sortField]){
        data = _.sortBy(this.state.data, schema[sortField].accessor);
        if(mode === 'desc') data = data.reverse();
      }
    }
    
   // data = _.orderBy(this.state.data, )
    
    if(data && data.length > 0){
      let sliceStart = this.props.serverSidePagination? 0 : currentPage - 1;

      dataRows = _.map(data.slice(sliceStart * pageSize, (sliceStart + 1) * pageSize), (v, i) => {
        nowRow++;
        return (
          <ZTBodyTr
            {...this.state}
            record={v}
            key={i}
            />
        );
      });
    }

    emptyRows = _.map(_.range(nowRow, minRow, 1), (v, i) => {
      return (
        <ZTBodyTr
          {...this.state}
          record={null}
          key={nowRow + i}
          />
      );
    });
    return [...dataRows, ...emptyRows];
  }
  
  render() {
    let {cssPrefix} = this.state;
    return(
      <tbody className={cssPrefix + " zt-tbody"}>
        {this.renderRow()}
      </tbody>
    );
  }
}

export default ZTBody;