import React, { Component } from "react";

import htmlParser from "html-react-parser";
import ZCTouch from "../ZCTouch";
import PropsType from "prop-types";
import _ from "lodash";

import ZCMVideo from "./ZCMVideo";
import ZTagParser from "./ZCMHTMLTags/ZTagParser";

import "./ZCMsg.css";

class ZCMTemplates extends Component {
  static propTypes = {
    templates: PropsType.array,
    disabled: PropsType.bool,
  };

  static defaultProps = {
    templates: [],
    disabled: true,
  };

  constructor() {
    super();
    this.state = {
      page: 0,
    };
    this.tps = [];
  }

  componentDidMount() {
    this.setState((state, props) => ({
      ...props,
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat) {
    if (prevStat !== nextProps) {
      return {
        ...nextProps,
      };
    }
  }

  _scrollIntoView(id, animated = true) {
    if (this.tps === null || !this.tps[id]) {
      return;
    }
    this.tps[id].scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
  }

  pageShift = (x) => {
    this.setState(
      (stat, props) => ({
        page: Math.max(0, Math.min(stat.page + x, stat.templates.length - 1)),
      }),
      () => {
        this._scrollIntoView(this.state.page);
      }
    );
  };

  renderTpButtons(tclassname, buttons) {
    let { cssPrefix, _onQuickReply, onPhoneClick, onWebClick, disabled } = this.state;

    let rendered = [];
    _.map(buttons, (o, i) => {
      let func = null;
      let last = i === buttons.length - 1;
      switch (o.type) {
        case "web":
          func = () => onWebClick(o.payload);
          break;
        case "phone":
          func = () => onPhoneClick(o.payload);
          break;
        default:
          func = () => _onQuickReply(o);
          break;
      }

      rendered.push(
        <div key={i} className={tclassname + "-btn " + (disabled ? "disabled " : "") + (last ? "last " : "")}>
          <ZCTouch cssPrefix={cssPrefix} onPress={func} disabled={disabled}>
            <div className={tclassname + "-btn-text " + (disabled ? "disabled " : "") + (last ? "last " : "")}>{o.title}</div>
          </ZCTouch>
        </div>
      );
    });

    return rendered;
  }

  renderTemplates() {
    let { cssPrefix, templates, HTMLEnabled, HTMLSpecialTagParser, iaddOns } = this.state;
    let rendered = [];
    let tclassname = cssPrefix + " zchat-msg-tp";
    _.map(templates, (o, i) => {
      let text = "";
      if (o.text) {
        if (HTMLEnabled) {
          let parsed = htmlParser(o.text);
          if (HTMLSpecialTagParser) {
            text = HTMLSpecialTagParser(cssPrefix, parsed, iaddOns);
          } else {
            text = ZTagParser.Parse(cssPrefix, parsed, iaddOns);
          }
        } else {
          text = o.text;
        }
      }

      let hasButtons = o.buttons && o.buttons.length > 0;
      let hasText = o.text && o.text !== "";

      rendered.push(
        <div key={i} className={tclassname} ref={(e) => (this.tps[i] = e)}>
          <div className={tclassname + "-title-border"}>
            <div className={tclassname + "-title"}>{o.title}</div>
          </div>
          <div className={tclassname + "-subtitle-border"}>
            <div className={tclassname + "-subtitle"}>{o.subtitle}</div>
          </div>
          {o.video ? (
            <div className={tclassname + (hasText || hasButtons ? "-video-border" : "-video-border-end")}>
              <div className={tclassname + "-video"}>
                <ZCMVideo cssPrefix={cssPrefix} video={o.video} />
              </div>
            </div>
          ) : o.image ? (
            <div className={tclassname + (hasText || hasButtons ? "-img-border" : "-img-border-end")}>
              <div className={tclassname + "-img"}>
                <img src={o.image} alt="" draggable={false} />
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {hasText && (
            <div className={tclassname + (hasButtons ? "-text-border" : "-text-border-end")}>
              <div className={tclassname + "-text"}>{text}</div>
            </div>
          )}
          {hasButtons && this.renderTpButtons(tclassname, o.buttons)}
        </div>
      );
    });

    return rendered;
  }

  render() {
    let { cssPrefix, templates } = this.state;
    return (
      <div className={cssPrefix + " zchat-msg-tcontainer"}>
        {templates.length > 0 && (
          <div key={"left"} className={cssPrefix + " zchat-msg-tcontainer-arrows"}>
            <ZCTouch cssPrefix={cssPrefix} onPress={() => this.pageShift(-1)}>
              <i className="fas fa-chevron-left" />
            </ZCTouch>
          </div>
        )}
        <div className={cssPrefix + " zchat-msg-tlist"} ref={(e) => (this.refT = e)}>
          {this.renderTemplates()}
        </div>

        {templates.length > 0 && (
          <div key={"right"} className={cssPrefix + " zchat-msg-tcontainer-arrows"}>
            <ZCTouch cssPrefix={cssPrefix} onPress={() => this.pageShift(1)}>
              <i className="fas fa-chevron-right" />
            </ZCTouch>
          </div>
        )}
      </div>
    );
  }
}

export default ZCMTemplates;
