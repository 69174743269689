/*REACT*/
import React, { Component } from 'react';
import PropsType from 'prop-types';

/*BASE*/
import _ from 'lodash';

/*CSS*/
import './ZTablizar.css';

import ZTNavBar from './ZTNavBar';
import ZTHeader from './ZTHeader';
import ZTBody from './ZTBody';
import ZTFooter from './ZTFooter';
import ZTNoData from './ZTNoData';

class ZTablizar extends Component {

  /*
  schema format:
  [
    {
      Header: string,
      accessor: string, //for access value in json object
      Cell?: function (data, field-data, state),
      sortable?: bool | false,
      format?: "date" | "datetime" | null, //auto convert to date
    }
  ]
  */
 
  static propTypes = {

    //css class
    cssPrefix: PropsType.string,

    //pagination
    pagination: PropsType.bool,
    serverSidePagination: PropsType.bool,
    onPageChangeTo: PropsType.func,
    
    //page size
    showPageSizeOptions: PropsType.bool,
    defaultPageSize: PropsType.number,
    pageSizeOptions: PropsType.arrayOf(PropsType.number),
    onPageSizeChange: PropsType.func,
    showIndicator: PropsType.bool,
    showTotalPages: PropsType.bool,
    showFastFlip: PropsType.bool,
    unlimitedPages: PropsType.bool,
    hasNextPage: PropsType.bool,

    //noData
    noDataText: PropsType.oneOfType([
      PropsType.string, 
      PropsType.element
    ]),

    //table
    minRow: PropsType.number,
    scrollableRows: PropsType.bool,

    //row
    showSelector: PropsType.bool,
    inlineButtons: PropsType.array,
    leftButtons: PropsType.array,
    rightButtons: PropsType.array,
    inlineButtonsAlign: PropsType.string, // 'left' || 'right'

    //cell
    cellSpacing: PropsType.number,
    hidePassword: PropsType.bool, 
    
    //navBar
    topNavBar: PropsType.bool,
    bottomNavBar: PropsType.bool,

    //FaIcon Preset
    useFaIcon: PropsType.bool,
    faIcon: PropsType.object,

    //runtime data
    schema: PropsType.array,
    data: PropsType.array,
    totalEntries: PropsType.number,
    identifier: PropsType.string,

    //authority
    authority: PropsType.array,
    restricted: PropsType.bool,
    allPass: PropsType.string

  }

  static defaultProps = {

    cssPrefix: "",

    pagination: false,
    serverSidePagination: false,
    
    onPageChangeTo: () => {},

    showPageSizeOptions: false,
    defaultPageSize: 10,
    pageSizeOptions: [10, 25, 50, 100],
    onPageSizeChange: () => {},
    showIndicator: true,
    showTotalPages: true,
    showFastFlip: true,
    unlimitedPages: false, 
    hasNextPage: false,

    noDataText: "No Data Available.",

    minRow: 10,
    scrollableRows: true,

    totalEntries: undefined,
    
    showSelector: false,
    inlineButtons: [], // [{caption: "", icon: "", func: onXXX, title: "", restricted: [""]}, ...]
    leftButtons: [], 
    rightButtons: [],
    inlineButtonsAlign: 'left',

    cellspacing: 0,
    hidePassword: false,

    topNavBar: true,
    bottomNavBar: true,

    useFaIcon: true,
    faIcon: 
    {
      edit: "fas fa-edit",
      delete: "fas fa-trash",
      info: "fas fa-info-circle",
      left: "fas fa-chevron-left",
      right: "fas fa-chevron-right",
      forward: "fas fa-angle-double-right",
      backward: "fas fa-angle-double-left",
      add: "fas fa-plus-square",
      import: "fas fa-upload",
      export: "fas fa-download",
      push: "fas fa-cloud-upload-alt",
      pull: "fas fa-cloud-download-alt",
      sync: "fas fa-sync-alt",
      revert: "fas fa-history",
      save: "fas fa-save",
      eye: "fas fa-eye",
      eyeslash: "fas fa-eye-slash",
      comment: "fas fa-comment-dots",
      play: "fas fa-play",
      pause: "fas fa-pause",
      stop: "fas fa-stop",
      learn: "fas fa-chalkboard-teacher",
      check: "fas fa-check",
      cross: "fas fa-times",
      question: "fas fa-question",
      correct: "far fa-check-circle",
      circle: "fad fa-circle"
    },

    identifier: '',

    authority: [],
    restricted: false,
    allPass: '',
  };

  constructor(){
    super();
    this.curPageDiv = React.createRef();
    this.state = {
      totalPage: 1,
      currentPage: 1,
      noData: true,
      sortField: -1,
      sortMode: 0,
      pageSize: 10,
      selected: []
    }
  }

  componentDidMount(){
    this.setState((state, props) => ({ 
      ...props,
      pageSize: props.defaultPageSize
    }), () => {
      this.CalculatePage();
    });
  }

  CalculatePage = () => {
    let {data, pagination, pageSize, minRow, currentPage} = this.state;
    let totalEntries = this.props.totalEntries || (data && !_.isEmpty(data)? data.length : 0);
    let totalPage = pagination? Math.ceil(totalEntries / pageSize) : 1;
    this.setState({
      minRow: pagination? pageSize: minRow,
      noData: data && !_.isEmpty(data) && data.length > 0 ? false : true,
      totalPage: totalPage,
      totalEntries: totalEntries
    }, () => {
      this.onPageJump(currentPage);
    }); 
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps !== this.props){
      this.setState((state, props) => ({ 
        ...props,
      }), () => {
        this.CalculatePage();
      });
    }
  }

  changePageSize = (v) => {
    this.setState({
      pageSize: v
    }, () => {
      this.state.onPageSizeChange(v);
    })
  }

  onPageJump = (v) => {
    let {currentPage, unlimitedPages} = this.state;
    if(unlimitedPages || currentPage === v) return;

    this.setState((state, props) => ({
      currentPage: Math.max(1, Math.min(v, state.totalPage))
    }), () => {
      this.state.onPageChangeTo(this.state.currentPage);
    });
  }

  onPageChange = (v) => {
    let {currentPage, totalPage, unlimitedPages, hasNextPage} = this.state;

    if(unlimitedPages){
      if (currentPage === 1 && v < 0) return;
      if (!hasNextPage && v > 0) return;

      this.setState((state, props) => ({
        currentPage: Math.max(1, currentPage + v)
      }), () => {
        this.state.onPageChangeTo(this.state.currentPage);
      });

    }else{
      if(totalPage === 0) return;
      if ((currentPage === 1 && v < 0) || 
        (currentPage === totalPage && v > 0)){return;}

      this.setState((state, props) => ({
        currentPage: Math.max(1, Math.min(state.currentPage + v, state.totalPage))
      }), () => {
        this.state.onPageChangeTo(this.state.currentPage);
      });
    }
    
  }

  onDataSort = (field, mode) => {
   
    if(mode === null){
      if(this.state.sortMode === 1) mode = -1;
      else mode = 1;
    }

    this.setState({
      sortField: field,
      sortMode: mode
    })
  }

  allSelectedInPage = async (recs) => {
    if(!recs) return false;
    console.log(recs)
    let arr = await _.map(recs, (o, i) => {
      let idx = this.checkInSelected(o);
      return (idx >= 0);
    })
    let rtn = await arr.includes(false);
    console.log(rtn)
    return rtn
  }

  checkInSelected = (rec) => {
    let {selected} = this.state;
    for (let i = 0; i < selected.length; i++){
      if(_.isEqual(selected[i], rec))
      return i;
    }
    return -1;
  }

  onSelectAllRecords = async (v) => {
    let {data, selected} = this.state;
    let newarr = selected;
    
    await _.map(data, (o, i) => {
      let idx = this.checkInSelected(o);
      if(v &&  idx < 0){
        newarr.push(o);
      }else if(!v && idx >= 0){
        newarr.splice(idx, 1);
      }
    });

    this.setState({
      selected: newarr
    }, ()=>{
      console.log(this.state.selected)
    })
    
  }

  onSelectRecord = (rec, v) => {
    let {selected} = this.state;
    if(v && this.checkInSelected(rec) < 0){
      this.setState({
        selected: [...selected, rec]
      })
    }else if(!v){
      let idx = this.checkInSelected(rec);
      let newarr = selected;
      newarr.splice(idx, 1)
      if(idx >= 0){
        this.setState({
          selected: newarr
        })
      }
    }    
  }

  getByAccessor = (obj, accessor) => {
    if(!accessor || !obj) return undefined;
    let fields = accessor.split('.');
    let rtn = obj;
  
    for(let i=0; i<fields.length; i++){
      if(rtn === null || rtn === undefined) return undefined;
      rtn = rtn[fields[i]];
    }
  
    return rtn;
  }

  GetPass = (restricted) => {
    let {authority, allPass} = this.state;
    if(!authority) return false;

    for(let i=0; i<authority.length; i++){
      let o = authority[i];
      if(restricted.includes(o)) {return true;}
    }

    if(!_.isEmpty(allPass)){
      if(authority.includes(allPass)) {return true;}
    }

    return false;
  }

  render(){
    let {cssPrefix, data, cellspacing, currentPage, totalPage, topNavBar, bottomNavBar, scrollableRows} = this.state;
    return(
      <div className={cssPrefix + " ztablizar"}>
        <ZTNavBar
          {...this.state}
          changePageSize={this.changePageSize}
          />
          {
            topNavBar?
            <ZTFooter
              {...this.state}
              totalPage={totalPage}
              currentPage={currentPage}
              onPageChange={this.onPageChange}
              onPageJump={this.onPageJump}
              GetPass={this.GetPass}
              />
              :""
          }
        <div className={cssPrefix + " zt-main" + (scrollableRows? " zt-scrollable" : "")}>
          {
            _.isEmpty(data)?
            <ZTNoData 
              {...this.state}
              />
            : ""
          }
          <table className={cssPrefix + " zt-table"} cellSpacing={cellspacing}>
            <ZTHeader 
              {...this.state}
              onDataSort={this.onDataSort}
              onSelectAllRecords={this.onSelectAllRecords}
              allSelectedInPage={this.allSelectedInPage}
              GetPass={this.GetPass}
              />
            <ZTBody
              {...this.state}
              currentPage={currentPage}
              onSelectRecord={this.onSelectRecord}
              checkInSelected={this.checkInSelected}
              getByAccessor={this.getByAccessor}
              GetPass={this.GetPass}
              />
          </table>
        </div>
        {
            bottomNavBar?
            <ZTFooter
              {...this.state}
              totalPage={totalPage}
              currentPage={currentPage}
              onPageChange={this.onPageChange}
              onPageJump={this.onPageJump}
              GetPass={this.GetPass}
              />
              :""
          }
      </div>
    );
  }


}

export default ZTablizar;
