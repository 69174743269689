/*REACT*/
import React, { Component } from 'react';

/*BASE*/
import ZTDropDownList from './ZTDropDownList';

/*CSS*/
import './ZTablizar.css';
import { isNumber } from 'util';

class ZTNavBar extends Component {

  constructor(){
    super();
    this.state = {}
  }

  componentDidMount(){
    this.setState((state, props) => ({
      ...props
    }), () => {
      if(this.state.showPageSizeOptions){
        this.PSODropDownList.set(this.state.pageSize);
      }
    });
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps !== this.props){
      this.componentDidMount();
    }
  }

  changePageSize = (v) => {
    if(this.state.showPageSizeOptions){
      this.PSODropDownList.set(v);
      this.state.changePageSize(v);
    }
  }

  mountPSODropDownList = (callbacks) => {
    this.PSODropDownList = callbacks;
  }

  renderPageSizeOption = () => {
    return (
      <div className="zt-pagesizeoption">
        <div className="zt-pso-text">
          Show
        </div>
        {
          this.state.showPageSizeOptions? 
          <div className="zt-dd-container"> 
            <ZTDropDownList
              onChange={this.changePageSize}
              onMounted={this.mountPSODropDownList}
              data={this.state.pageSizeOptions}/>
          </div>:
          <div className="zt-pso-text-bold">
            {this.state.pageSize}
          </div>
        }
          
        <div className="zt-pso-text">
          entries per page
        </div>
      </div>
    );
  }

  renderIndicator = () => {
    let {cssPrefix, currentPage, pageSize, totalEntries} = this.state;
    if(!isNumber(currentPage) || !isNumber(pageSize) || !isNumber(totalEntries)) return;
    return (
      <div className={cssPrefix + " zt-pageindicator"}>
        <div className={cssPrefix + " zt-pso-text"}>
          Displaying
        </div>
        <div className={cssPrefix + " zt-pso-text-bold"}>
          {(currentPage-1) * pageSize + 1}
        </div>
        <div className={cssPrefix + " zt-pso-text"}>
          to
        </div>
        <div className={cssPrefix + " zt-pso-text-bold"}>
          {Math.min(totalEntries, (currentPage-1) * pageSize + pageSize)}
        </div>
        <div className={cssPrefix + " zt-pso-text"}>
          of
        </div>
        <div className={cssPrefix + " zt-pso-text-bold"}>
          {totalEntries}
        </div>
        <div className={cssPrefix + " zt-pso-text"}>
          entries
        </div>
      </div>
    );
  }

  render() {
    let {cssPrefix, pagination, showIndicator} = this.state;
    return(
      <div className={cssPrefix + " zt-navbar"}>
        {pagination && this.renderPageSizeOption()}
        {showIndicator && this.renderIndicator()}
      </div>
    );
  }

}

export default ZTNavBar;