/*REACT*/
import React, { Component } from 'react';
import PropsType from 'prop-types';

/*BASE*/
import ZReduxFormInput from './ZReduxFormInput';

/*CSS*/
import './ZReduxForm.css';

class ZReduxFormField extends Component {

  static propTypes = {
    cssPrefix: PropsType.string,
    content: PropsType.object.isRequired,
    dValue: PropsType.any,
    type: PropsType.string
  }

  static defaultProps = {
    cssPrefix: '',
    content: null
  };

  constructor(){
    super();
    this.state = {};
  }

  componentDidMount(){
    this.setAllStates();
  }

  setAllStates = (callback) => {
    this.setState((state, props) => ({
      ...props 
    }));
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps !== this.props){
      this.setAllStates();
    }
  }

  render() {
    let {content, cssPrefix, readOnly, addOns} = this.state;
    return (
      <div className={cssPrefix + "zrf-field"}>
        <div className={cssPrefix + "zrf-field-title"}>
          {content && content.label}
        </div>
          {content && 
            <ZReduxFormInput 
              {...this.state}
              addOns={addOns}
              readOnly={readOnly}
              content={content}
              />
          }
      </div>
    );
  }
}

export default ZReduxFormField;
