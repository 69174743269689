import React, { Component } from 'react';
import PropsType from 'prop-types';
import { Player } from 'video-react';

import ZCTouch from '../ZCTouch';

import "./ZCMsg.css";
import "./ZCMVideo.css";

class ZCMVideo extends Component {

  static propTypes = {
    video: PropsType.object
  }

  static defaultProps = {
    video: {}
  }

  constructor(){
    super();
    this.state = {};
  }

  componentDidMount(){
    this.setState((state, props) => ({ 
      ...props
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat){
    if(prevStat !== nextProps){
      return {
        ...nextProps
      }
    }
  }

  render(){
    let { cssPrefix, video } = this.state;
    return (
      <div className={cssPrefix + " zchat-msg-video"}>
        <ZCTouch cssPrefix={cssPrefix} disabled={true}>
          <Player
            src={video.src}
            poster={video.poster}/>
        </ZCTouch>
      </div>
    )
  }
}

export default ZCMVideo;