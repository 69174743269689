import {AUTH_SIGNIN, AUTH_SIGNOUT} from '../_base/actions';

const INIT_STATE = {

}

export default (state = INIT_STATE, action) => {
  switch(action.type){
    case AUTH_SIGNIN:
      return action.payload;
    case AUTH_SIGNOUT:
      return INIT_STATE;
    default:
      return state;
  }
}