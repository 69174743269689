/*REACT*/
import React, { Component } from 'react';
import { connect } from 'react-redux';

/*BASE*/
import * as _base from '../../../_base';

/*CSS*/
import './NoMatch.css';

class NoMatch extends Component {
  render() {
    return (
      <div className="NoMatch">
        <div className="nopage">404</div>
        <img src="Images/404.jpg" alt=""/>
      </div>
    );
  }
}

export default connect(_base.func.mapStateToProps, _base.actions)(NoMatch);