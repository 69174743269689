export const mapStateToProps = (state, ownProps) => {
  return {
    consoleLog: state.consoleLog,
    locale: state.locale,
    user: state.user
  }
}

export const getByAccessor = (obj, accessor) => {
  if(!accessor || !obj) return undefined;
  let fields = accessor.split('.');
  let rtn = obj;

  for(let i=0; i<fields.length; i++){
    if(rtn === null) return undefined;
    rtn = rtn[fields[i]];
  }

  return rtn;
}

export const handleError = (props, e, alert) => {
  console.log(e);
  try{
    if(typeof (e.message) === "object"){
      if(e.message.Message){
        alert(JSON.stringify(e.message.Message));
      }else{
        alert(JSON.stringify(e.message));
      }
    }else{
      alert(e.message);
    }
  }catch(e){
    alert("Error");
  }

  switch(e.errorCode){
    case 4001: case 4002: case 4003: 
    props._signOut(); return;
    default: return;
  }
}
