/*REACT*/
import React, { Component } from 'react';

/*BASE*/
import _ from 'lodash';

/*CSS*/
import './ZTablizar.css';

import ZTPageFlip from './ZTPageFlip';
import ZTButton from './ZTButton';

class ZTFooter extends Component {

  constructor(){
    super();
    this.state = {}
  }

  componentDidMount(){
    this.setState((state, props) => ({
      ...props
    }));
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps !== this.props){
      this.componentDidMount();
    }
  }

  renderButtons = (btnArray, suffix) => {
    let {faIcon, restricted, GetPass} = this.state;
    return _.map(btnArray, (o, i) => {
      if(restricted && !_.isEmpty(o.restricted) && !GetPass(o.restricted)) return;
      return(
      <ZTButton
        {...this.state}
        cssSuffix={suffix}
        onClick={o.func}
        faClass={faIcon[o.icon]}
        caption={o.caption}
        title={o.title}
        key={i}
        />
      );
    });
  }

  render() {
    let {cssPrefix, leftButtons, onPageChange, rightButtons, pagination, onPageJump} = this.state;
    return(
      <div className={cssPrefix + " zt-footer"}>
        {this.renderButtons(leftButtons, "-footer-btn left")}
        {
          pagination? 
          <ZTPageFlip
            {...this.state}
            onPageChange={onPageChange}
            onPageJump={onPageJump}
            />
          : ""
        }
        {this.renderButtons(rightButtons, "-footer-btn right")}
      </div>
    );
  }

}

export default ZTFooter;