/*REACT*/
import React, { Component } from 'react';

/*BASE*/
import _ from 'lodash';

/*CSS*/
import './ZTablizar.css';

import ZTBodyTd from './ZTBodyTd';
import moment from 'moment';

class ZTBodyTr extends Component {

  constructor(){
    super();
    this.state = {}
  }

  componentDidMount(){
    this.setState((state, props) => ({
      ...props
    }));
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps !== this.props){
      this.componentDidMount();
    }
  }  

  renderCell = () => {
    let dataCells = [], inlineBtnCells = [], selectorCell = [];
    let cellNum = 0;
    let {record, schema, inlineButtons, faIcon, 
      cssPrefix, getByAccessor, onSelectRecord, 
      checkInSelected, restricted, GetPass, inlineButtonsAlign} = this.state;

    if(record){
      if(this.state.showSelector){
        cellNum ++;
        selectorCell = [
          <ZTBodyTd
            {...this.state}
            content={
              <input type='checkbox' onChange={(e) => onSelectRecord(record, e.target.checked)} checked={checkInSelected(record) >= 0}/>
            }
            secure={false}
            key={cellNum}
            />
        ];
      }

      dataCells = _.map(schema, (c, x) => {
        cellNum++;
        let fieldData = getByAccessor(record, c.accessor);
        let showData = c.Cell ? c.Cell(record, fieldData, this.state) : fieldData;
        if(c.format === "date"){
          showData = fieldData && fieldData.trim() === "" ? "" : moment(fieldData).format(c.dateFormat || 'YYYY-MM-DD');
        }else if (c.format === "datetime"){
          showData = fieldData && fieldData.trim() === "" ? "" : moment(fieldData).format(c.dateFormat || 'YYYY-MM-DD HH:mm:ss');
        }else if(c.format === "boolean" || c.format === "bool"){
          showData = fieldData ? <i className="fas fa-check"/> : <i className="fas fa-times"/>
        }else if(c.format === "onoff"){
          showData = fieldData ? <i className="fas fa-circle fa-xs greenF"/> : <i className="fas fa-circle fa-xs redF"/>     
        }else if(c.format === "download"){
          showData = fieldData ? <a target="_blank" rel="noopener noreferrer" href={fieldData}><i className="fas fa-lg fa-file-download"></i></a> : ""
        }else if(c.format === "percentage"){
          showData = fieldData ? 
            <div className={fieldData > 0 ? "green" : "red"}>
              {(fieldData > 0 ? "+" : "-") + Math.abs(fieldData)}%
            </div> : "--%"
        }
        return(
          <ZTBodyTd
            {...this.state}
            content={showData}
            cssClass={c.cssClass || (c.cssClassByFunc && c.cssClassByFunc(record, getByAccessor(record, c.accessor), this.state))}
            secure={c.secure || false}
            key={cellNum}
            />
        );
      });

      

      inlineBtnCells = _.map(inlineButtons, (o, i) => {
        if(restricted && !_.isEmpty(o.restricted) && !GetPass(o.restricted)) return;
        cellNum++;
        return(
          <ZTBodyTd
            {...this.state}
            content={
              <div onClick={() => { o.func(record); } } 
                className={cssPrefix + " zt-cellBtn"}
                title={o.title}
                >
                <i className={faIcon[o.icon]}/>
                </div>
            }
            key={cellNum}
          />
        );
      });

    }else{

      if(this.state.showSelector){
        cellNum++;
        selectorCell = [
          <ZTBodyTd
            {...this.state}
            content={null}
            secure={false}
            key={cellNum}
            />
        ]
      }
      

      dataCells = _.map(schema, (c, x) => {
        cellNum++;
        return(
          <ZTBodyTd
            {...this.state}
            content={null}
            secure={false}
            key={cellNum}
            />
        );
      });

      inlineBtnCells = _.map(inlineButtons, (o, i) => {
        if(restricted && !_.isEmpty(o.restricted) && !GetPass(o.restricted)) return;
        cellNum++;
        return(
          <ZTBodyTd
            {...this.state}
            content={null}
            key={cellNum}
            />
        );
      });
    }
    
    if(inlineButtonsAlign === 'left'){
      return [...selectorCell, ...inlineBtnCells, ...dataCells];
    }
    return [...selectorCell, ...dataCells, ...inlineBtnCells];
  }

  render() {
    let {key, cssPrefix} = this.state;
    return(
      <tr key={key} className={cssPrefix + " zt-tr"}>
        {this.renderCell()}
      </tr>
    );
  }

}

export default ZTBodyTr;