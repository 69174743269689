import {combineReducers} from 'redux';
import {reducer as formreducer} from 'redux-form';
import locale from './locale';
import consoleLog from './consoleLog';
import user from './user';

const rootReducer = combineReducers({
  form: formreducer,
  locale: locale,
  consoleLog: consoleLog,
  user: user
});

export default rootReducer;