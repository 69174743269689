/*REACT*/
import React, { Component } from 'react';

/*BASE*/

/*CSS*/
import './ZTablizar.css';

class ZTNoData extends Component {
  
  constructor(){
    super();
    this.state = {}
  }

  componentDidMount(){
    this.setState((state, props) => ({
      ...props
    }));
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps !== this.props){
      this.componentDidMount();
    }
  }

  render() {
    let {cssPrefix, noDataText} = this.state;
    return(
      <div className={cssPrefix + " zt-no-data"}>
        <div className={cssPrefix + " zt-no-data-text"}>
          {noDataText}
        </div>
      </div>
    );
  }

}

export default ZTNoData;