/*REACT*/
import React, { Component } from 'react';
import PropsType from 'prop-types';

/*BASE*/
import _ from 'lodash';

/*CSS*/
import './ZTablizar.css';

class ZTDropDownList extends Component {

  static propTypes = {
    cssPrefix: PropsType.string,
    capAccessor: PropsType.string,
    data: PropsType.array.isRequired
  }

  static defaultProps = {
    cssPrefix: '',
    capAccessor: '',
    data: []
  };

  constructor(props){
    super();
    this.state = {
      inuse: false,
      selected: null
    };
  }

  componentDidMount(){
    this.setAllStates();
    if(this.props.onMounted){
      this.props.onMounted({
        set: (value) => this.set(value)
      });
    }
  }

  componentDidUpdate(prevProp, prevStat){
    if(prevProp.data !== this.props.data){
      this.setAllStates();
    }
  }

  componentWillUnmount(){
    document.removeEventListener('click', this.clickOutside, false);
  }

  setAllStates = () => {
    this.setState((state, props) => ({
      ...props
    }));
  }

  dropDown = () => {

    if(!this.state.inuse){
      document.addEventListener('click', this.clickOutside, false);
    }else{
      document.removeEventListener('click', this.clickOutside, false);
    }

    this.setState((state, props) => ({
      inuse: !state.inuse
    }));
  }

  clickOutside = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.dropDown();
  }

  select = (v) => {
    this.props.onChange(v);
    this.dropDown();
  }

  set = (value) => {
    this.setState({
      selected: value
    });
  }

  getNestedValue = (obj, accessor) => {
    if(accessor==='') return obj;
    let fields = accessor.split('.');
    for(let i=0; i<fields.length; i++){
      if(typeof obj != undefined){
        obj = obj[fields[i]];
      }
    }
    return obj;
  }

  renderItems = () => {
    let {cssPrefix, capAccessor, data} = this.state;
    return _.map(data, (v, i) => {
      return (
        <div className={cssPrefix + " zt-dd-list-item"} 
          onClick={()=>this.select(v)} key={i}>
          {v && this.getNestedValue(v, capAccessor)}
        </div>
      );
    });
  }

  render() {
    let {cssPrefix, capAccessor, inuse, selected} = this.state;
    return (
      <div className={cssPrefix + " zt-ZDropDownList"} ref={node => { this.node = node; }}>
        <div className={cssPrefix + " zt-dd-wrapper"}>
          <div className={cssPrefix + " zt-dd-header"} onClick={this.dropDown}>
            <div className={cssPrefix + " zt-dd-header-title"}>
              {selected && this.getNestedValue(selected, capAccessor)}
            </div>
            <div className={cssPrefix + " zt-dd-header-arrow"}>
              <i className="fas fa-chevron-down"/>
            </div>
          </div>
          <div className={cssPrefix + " zt-dd-list"}>
            {inuse && this.renderItems()}
          </div>
        </div>
      </div>
    );
  }
}

export default ZTDropDownList;
