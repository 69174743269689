/*REACT*/
import React, { Component } from 'react';

/*BASE*/

/*CSS*/
import './ZTablizar.css';

class ZTHeaderTh extends Component {

  constructor(){
    super();
    this.state = {}
  }

  componentDidMount(){
    this.setState((state, props) => ({
      ...props
    }));
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps !== this.props){
      this.componentDidMount();
    }
  }

  render() {
    let {cssPrefix, sortable, sortField, sortMode, field, onDataSort, content} = this.state;
    return(
      <th className={cssPrefix + " zt-th" + 
        (sortable && sortField === field? 
        (sortMode === -1 ? " desc" : (sortMode===1 ? " asc" : "")): "")}
        onClick={() => {
          if(sortable){
            if(sortField === field){
              onDataSort(field, null);
            }else{
              onDataSort(field, 1);
            }
          }
        }}
        >
        {content? content : <div>&nbsp;</div>}
      </th>
    );
  }
}

export default ZTHeaderTh;