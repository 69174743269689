/*REACT*/
import React, { Component } from 'react';
import PropsType from 'prop-types';
import { Field } from 'redux-form';

/*BASE*/
import _ from 'lodash';

/*CSS*/
import './ZReduxForm.css';

class ZReduxFormInput extends Component {

  static propTypes = {
    cssPrefix: PropsType.string,
    content: PropsType.object.isRequired,
    dValue: PropsType.any,
    type: PropsType.string
  }

  static defaultProps = {
    cssPrefix: '',
    content: null
  };

  constructor(){
    super();
    this.state = {};
  }

  componentDidMount(){
    this.setAllStates(()=>{
      if(this.props.dValue){
        this.fillTheForm(this.props.dValue);
      }
    });
  }

  setAllStates = (callback) => {
    this.setState((state, props) => ({
      ...props 
    }), () => {
      this.setState((state, props) => ({
        type: props.type || this.switchType(state.content.format)
      }), callback);
    });
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps !== this.props){
      this.setAllStates();
    }

    if(prevProps.dValue !== this.props.dValue){
      if(this.props.dValue){
        this.fillTheForm(this.props.dValue);
      }
    }
  }

  fillTheForm = (v) => {
    if(this.state.type === "radio"){
      this.onRadioClick(this.state.content.name, v);
    }
  }

  switchType = (format) => {
    let f = format ? format.toLowerCase() : undefined;
    switch(f){
      case 'text': return 'text';
      case 'shorttext': return 'text';
      case 'longtext': return 'text';
      case 'dropdown': return 'text';
      case 'date': return 'date';
      case 'datetime': return 'datetime';
      case 'textarea':  return 'text';
      case 'radio': return 'radio';
      case 'checkbox': return 'checkbox';
      case 'number': return 'number';
      default: return 'text';
    }
  }

  onRadioClick = (name, value) => {
    this.setState({
      [name]: value
    });
  }

  shortTextField = ({input, placeholder, readOnly, meta:{touched, error, warning}}) => (
    <input {...input} placeholder={placeholder} type="text" 
    className={this.state.cssPrefix + "zrf-field-textfield short" + (touched && error ? " error": "")}
    readOnly={readOnly}
    /> 
  );

  longTextField =  ({input, placeholder, readOnly, meta:{touched, error, warning}}) => (
    <input {...input} placeholder={placeholder} type="text" 
    className={this.state.cssPrefix + "zrf-field-textfield long" + (touched && error ? " error": "")}
    readOnly={readOnly}
    /> 
  );

  passwordField = ({input, placeholder, readOnly, meta:{touched, error, warning}}) => (
    <input {...input} placeholder={placeholder} type="password" 
    className={this.state.cssPrefix + "zrf-field-textfield short" + (touched && error ? " error": "")}
    readOnly={readOnly}
    /> 
  );

  dropDownList = ({input, selectRef, selectCap, selectVal, readOnly, meta:{touched, error, warning}}) => (
    <select {...input} className="zrf-field-dropdown" disabled={readOnly}>
      {this.dropDownOptions(selectRef, selectCap, selectVal)}
    </select>
  );

  dropDownOptions = (selectRef, selectCap, selectVal) => {
    return _.map (selectRef, (o, i) => {
      return (
        <option value={o[selectVal]} key={i}>{o[selectCap]}</option>
      );
    });
  }

  datePicker = ({input, placeholder, readOnly, type,  meta:{touched, error, warning}}) => (
    <input {...input} placeholder={placeholder} type={type}
    className={this.state.cssPrefix + "zrf-field-date" + (touched && error ? " error": "")}
    readOnly={readOnly}
    /> 
  );

  textArea = ({input, placeholder,  resizeable, readOnly, rows, cols, meta:{touched, error, warning}}) => (
    <textarea {...input} placeholder={placeholder} type="text" rows={rows} cols={cols}
    className={this.state.cssPrefix + "zrf-field-textfield short" + (touched && error ? " error": "") + (resizeable? "" : " notresizeable")}
    readOnly={readOnly}
    /> 
  );

  renderRadioOptions = (name, selectRef, selectCap, selectVal, readOnly) => {
    return _.map (selectRef, (o, i) => {
      return (
        <label key={i} className="zrf-field-radio">
          <Field name={name} type="radio" 
          value={o[selectVal]} 
          validate={this.getValidate()} component="input" 
          onChange={()=>this.onRadioClick(name, o[selectVal])} 
          checked={this.state[name] === o[selectVal]}
          readOnly={readOnly}
          disabled={readOnly}
          />
          {o[selectCap]}
        </label>
      );
    });
  }

  renderCheckboxOptions =  (name, selectRef, selectCap, selectVal, readOnly) => {
    return _.map (selectRef, (o, i) => {
      return (
        <label key={i} className="zrf-field-checkbox">
          <Field name={name + "_" + o[selectVal]} type="checkbox" component="input" disabled={readOnly}/>
          {o[selectCap]}
        </label>
      );
    });
  }

  number = ({input, placeholder, min, max, readOnly, meta:{touched, error, warning}}) => (
    <input {...input} type="number" className={this.state.cssPrefix + "zrf-field-number" + (touched && error ? " error": "")} 
    placeholder={placeholder}
    min={min}
    max={max}
    readOnly={readOnly}
    /> 
  )

  fileField = ({ input: {value: omitValue, ...inputProps }, accept, meta:{touched, error, warning} }) => (
    <input type="file"
    className={this.state.cssPrefix + "zrf-field-file" + (touched && error ? " error": "")} 
    accept={accept}
    {...inputProps}
    />
  )

  renderInput = (format) => {
    let {content, readOnly, addOns, data, getByAccessor} = this.state;
    let c = content;
    let f = format ? format.toLowerCase() : undefined;
    switch(f){
      case 'text': case 'shorttext': 
      return (
        <Field name={c.name} type="text" 
        placeholder={c.placeholder || ""} 
        component={this.shortTextField} 
        validate={this.getValidate()}
        readOnly={readOnly || c.readOnly}
        />
      );
      case 'longtext': 
      return (
        <Field name={c.name} type="text" 
        placeholder={c.placeholder || ""} 
        component={this.longTextField} 
        validate={this.getValidate()}
        readOnly={readOnly || c.readOnly}
        />
      );
      case 'password':
      return (
        <Field name={c.name} type="password" 
        placeholder={c.placeholder || ""} 
        component={this.passwordField} 
        validate={this.getValidate()}
        readOnly={readOnly || c.readOnly}
        />
      );
      case 'dropdown': 
      return (
        <Field name={c.name} type="select"
        component={this.dropDownList}
        selectRef={addOns[c.selectRef]}
        selectCap={c.selectCap}
        selectVal={c.selectVal}
        validate={this.getValidate()}
        readOnly={readOnly || c.readOnly}
        />
      );
      case 'date': 
      return (
        <Field name={c.name} type="date" 
        placeholder={c.placeholder || "YYYY/MM/DD"} 
        component={this.datePicker} 
        validate={this.getValidate()}
        readOnly={readOnly || c.readOnly}
        />
      );
      case 'time': 
      return (
        <Field name={c.name} type="time" 
        placeholder={c.placeholder || "HHmm"} 
        component={this.datePicker} 
        validate={this.getValidate()}
        readOnly={readOnly || c.readOnly}
        />
      );
      case 'datetime': 
      return (
        <Field name={c.name} type="datetime" 
        placeholder={c.placeholder || "YYYY/MM/DD HH:mm:ss"} 
        component={this.datePicker} 
        validate={this.getValidate()}
        readOnly={readOnly || c.readOnly}
        />
      );
      case 'textarea': 
      return (
        <Field name={c.name} type="text" 
        placeholder={c.placeholder || ""} 
        component={this.textArea} 
        validate={this.getValidate()}
        rows={c.rows || "4"}
        cols={c.cols || "50"}
        resizeable={c.resizeable || false}
        readOnly={readOnly || c.readOnly}
        />
      );
      case 'radio': 
      return (
        <div>
          {this.renderRadioOptions(c.name, addOns[c.selectRef], c.selectCap, c.selectVal, readOnly || c.readOnly)}
        </div>
      );
      case 'checkbox': 
      return (
        <div>
          {this.renderCheckboxOptions(c.name, addOns[c.selectRef], c.selectCap, c.selectVal, readOnly || c.readOnly)}
        </div>
      );
      case 'number': return (
      <Field name={c.name} type="number" 
        placeholder={c.placeholder || ""} 
        min={c.min}
        max={c.max}
        component={this.number} 
        validate={this.getValidate()}
        readOnly={readOnly || c.readOnly}
        />
      );
      case 'file' : return (
        <Field name={c.name} type="file" 
          component={this.fileField}
          validate={this.getValidate()}
          readOnly={readOnly || c.readOnly}
          accept={c.accept}
        />
      );
      case 'bool': case 'boolean': return (
        <label className="zrf-field-checkbox">
          <Field name={c.name} type="checkbox" component="input" disabled={readOnly || c.readOnly}/>
        </label>
      );
      case 'display' : return (
        c.Cell && c.Cell(data, getByAccessor(data, c.name), this.state)
      );

      default: return (<div></div>);
    }
  }

  getValidate = () => {
    return _.map(this.state.content.validate, (o, i) => {
      return this.validate[o];
    });
  }

  //validation
  validate = {
    required: value => value !== undefined ? undefined : 'Required',
    email: value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? "Invalid email address" : undefined,
    number: value => value && isNaN(Number(value))? "Must be a number" : undefined
  };

  render() {
    let {content} = this.state;
    if(content)
      return this.renderInput(content.format);
    else
      return (<div></div>)
  }
}

export default ZReduxFormInput;
