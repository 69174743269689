import React, { Component } from 'react';
import PropsType from 'prop-types';

import ZCTouch from '../ZCTouch';

import "./ZCMsg.css";

class ZCMImage extends Component {

  static propTypes = {
    src: PropsType.string,
  }

  static defaultProps = {
    src: "",
  }

  constructor(){
    super();
    this.state = {};
  }

  componentDidMount(){
    this.setState((state, props) => ({ 
      ...props
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat){
    if(prevStat !== nextProps){
      return {
        ...nextProps
      }
    }
  }

  render(){
    let { cssPrefix, src, onImageClick, title } = this.state;
    return (
      <ZCTouch onPress={()=>onImageClick(src)}>
        <div className={cssPrefix + " zchat-msg-image"}>
          <img src={src} alt="" title={title}/>
        </div>
      </ZCTouch>
    );
  }
}

export default ZCMImage;