/*REACT*/
import React, { Component } from 'react';
import PropsType from 'prop-types';
import _ from 'lodash';

class ZRestrict extends Component {

  static propTypes = {
    blacklist: PropsType.array,
    restricted: PropsType.array,
    whitelist: PropsType.array,
    allPass: PropsType.string,
    auth: PropsType.array
  }

  static defaultProps = {
    blacklist: [],
    restricted: [],
    whitelist: [],
    auth: []
  };

  constructor(){
    super();
    this.state = {};
  }

  componentDidMount(){
    this.setState((state, props) => ({
      ...props
    }), () => {
      this.setState((state, props) => ({
        pass: this.GetPass(state.auth)
      }));
    })
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps !== this.props){
      this.componentDidMount();
    }
  }

  GetPass = (authorized) => {
    let { whitelist, blacklist, restricted, allPass} = this.state;
    if(!authorized) return false;
    for(let i=0; i<authorized.length; i++){
      let o = authorized[i];
      if(whitelist.includes(o)) {return true;}
      if(blacklist.includes(o)) {return false;}
      if(restricted.includes(o)) {return true;}
    }
    if(_.isEmpty(restricted)) {
      return true;
    }

    if(!_.isEmpty(allPass)){
      if(authorized.includes(allPass)) {return true;}
    }
    return false;
  }

  render() {
    let {pass} = this.state;
    return (
    <div>
      {
        pass ? this.props.children : ""
      }
    </div>
    );
  }
}

export default ZRestrict;
