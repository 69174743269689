/*REACT*/
import React, { Component } from 'react';

/*BASE*/

/*CSS*/
import './ZTablizar.css';

class ZTButton extends Component {

  /*Props list
    
  */
  constructor(){
    super();
    this.state = {};
  }

  componentDidMount(){
    this.setState((state, props) => ({
      ...props
    }));
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps !== this.props){
      this.componentDidMount();
    }
  }

  render() {
    let {cssPrefix, cssSuffix, data, title, useFaIcon, faClass, caption, onClick, selected} = this.state;
    return(
      <div className={cssPrefix + " zt" + cssSuffix} onClick={() => onClick(data, selected)} title={title}>
      {
        useFaIcon?
        <i className={faClass + " fa-lg"}/> : ""
      }  
      {caption}
      </div>
    );
  }

}

export default ZTButton;