import React, { Component } from 'react';
import PropsType from 'prop-types';
import htmlParser from 'html-react-parser';
import ZTagParser from './ZCMHTMLTags/ZTagParser';

import _ from 'lodash';

import "./ZCMsg.css";

class ZCMText extends Component {

  static propTypes = {
    text: PropsType.string,
  }

  static defaultProps = {
    text: ""
  }

  constructor(){
    super();
    this.state = {
      hide: true
    };
  }

  componentDidMount(){
    this.setState((state, props) => ({ 
      ...props
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat){
    if(prevStat !== nextProps){
      return {
        ...nextProps
      }
    }
  }

  ReplaceSpecialTags = (text) => {
    let parsed = htmlParser(text);
    let {cssPrefix, HTMLSpecialTagParser, iaddOns} = this.state;

    if(HTMLSpecialTagParser){
      return HTMLSpecialTagParser(cssPrefix, parsed, iaddOns);
    }else{
      return ZTagParser.Parse(cssPrefix, parsed, iaddOns);
    }
  }

  setHide = (f) => {
    this.setState({
      hide: f
    });
  }

  renderReadMore(){
    let {cssPrefix, readMoreCaption} = this.state;
    return (
      <div key="ReadMore" className={cssPrefix + " zchat-msg-text-readmore"} onClick={() => this.setHide(false)}>
        {readMoreCaption}
      </div>
    );
  }

  renderReadLess(){
    let {cssPrefix, readLessCaption} = this.state;
    return (
      <div key="ReadLess" className={cssPrefix + " zchat-msg-text-readmore"} onClick={() => this.setHide(true)}>
        {readLessCaption}
      </div>
    );
  }

  renderText(text){
    let {cssPrefix, HTMLEnabled, hideLongAnswer, longAnswerLength,
      revertReadMore, hide} = this.state;
    
    let rtn = [];
  
    if(HTMLEnabled){
      let blocks = this.ReplaceSpecialTags(text);
      if(hideLongAnswer){
        if(!Array.isArray(blocks)) {
          blocks = [blocks];
        }

        let length = 0;
        let short = false;
        _.map(blocks, (o, i) => {

          if(o.props && o.props.children){
            if(typeof(o.props.children) === "string"){
              length += o.props.children.length;
            }
          }else{
            if(typeof(o) === "string"){
              length += o.length;
            }
          }

          if(length < longAnswerLength){
            if(i === blocks.length - 1){
              short = true;
            }
            rtn.push(o);
          }else{
            if(!hide){
              rtn.push(o);
            }
          }

        });

        if(!short){
          if(hide){
            rtn.push(
              this.renderReadMore()
            );
          }else if(!hide && revertReadMore){
            rtn.push(
              this.renderReadLess()
            );
          }
        }
      }
    }else{
      rtn = text;
      
      if(hideLongAnswer){
        if(text.length > longAnswerLength){
          let showText = text.substring(0, longAnswerLength);
          let hideText = text.substring(longAnswerLength);

          rtn = [
            <div className={cssPrefix + " zchat-msg-text-box"}>
              <div key="showtext" className={cssPrefix + " zchat-msg-text-show"}>
                {showText}
              </div>
              {
                !hide &&
                <div key="hidetext" className={cssPrefix + " zchat-msg-text-hide"}>
                  {hideText}
                </div>
              }
              {
                hide &&
                this.renderReadMore()
              }
              {
                !hide && revertReadMore &&
                this.renderReadLess()
              }
            </div>
          ];
        }
      }
    }

    return rtn;

  }

  render(){
    let { cssPrefix, text } = this.state;
    return (
      <div className={cssPrefix + " zchat-msg-text"}>
        {this.renderText(text)}
      </div>
    )
  }
}

export default ZCMText;