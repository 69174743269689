/*REACT*/
import React, { Component } from 'react';

/*BASE*/

/*CSS*/
import './ZTablizar.css';

class ZTPageFlip extends Component {

  constructor(){
    super();
    this.inputPage = React.createRef();
    this.state = {};
  }

  componentDidMount(){
    this.setState((state, props) => ({
      ...props
    }), () => {
      this.inputPage.value = this.state.currentPage;
    });
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps !== this.props){
      this.componentDidMount();
    }
  }

  onInputPage = () => {
    let {onPageJump} = this.state;
    if(onPageJump){
      onPageJump(this.inputPage.value);
    }
  }

  render() {
    let {cssPrefix, onPageChange, onPageJump, useFaIcon, faIcon, totalPage, showTotalPages, showFastFlip, unlimitedPages} = this.state;
    return(
      <div className={cssPrefix + " zt-page-flip"}>
        {showFastFlip && 
          <div className={cssPrefix + " zt-page-btn"} 
            onClick={() => {if(onPageChange) onPageJump(1);} }>
            { useFaIcon ?
              <i className={faIcon["backward"] + " fa-lg"}/> : "<<"
            }
          </div>
        }
        <div className={cssPrefix + " zt-page-btn"} 
          onClick={() => {if(onPageChange) onPageChange(-1);} }>
          { useFaIcon ?
            <i className={faIcon["left"] + " fa-lg"}/> : "<<"
          }
        </div>
        <input ref={input => this.inputPage = input} className=" zt-page-input" 
        type="number" onBlur={this.onInputPage} readOnly={unlimitedPages}/>
        {showTotalPages &&
          <div className={cssPrefix + " zt-page-total"}>
            {"/ " + totalPage}
          </div>
        }
        <div className={cssPrefix + " zt-page-btn"} 
          onClick={() => {if(onPageChange) onPageChange(1);}}>
          { useFaIcon ?
            <i className={faIcon["right"] + " fa-lg"}/> : ">>"
          }
        </div>
        {showFastFlip && 
          <div className={cssPrefix + " zt-page-btn"} 
            onClick={() => {if(onPageChange) onPageJump(totalPage);} }>
            { useFaIcon ?
              <i className={faIcon["forward"] + " fa-lg"}/> : "<<"
            }
          </div>
        }
      </div>
    );
  }
}

export default ZTPageFlip;
