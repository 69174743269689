/*REACT*/
import React, { Component } from 'react';

/*BASE*/
import _ from 'lodash';

/*CSS*/
import './ZReduxForm.css';

import ZReduxFormField from './ZReduxFormField';
import ZReduxFormArray from './ZReduxFormArray';
import ZReduxFormInput from './ZReduxFormInput';

class ZReduxFormRow extends Component {

  static propTypes = {

  }

  static defaultProps = {

  };

  componentDidMount(){
    this.setAllStates();
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps !== this.props){
      this.setAllStates();
    }
  }

  setAllStates = (callback) => {
    
    this.setState((state, props) => ({
      ...props
    }), callback);
  }
  
  render() {
    if(!this.state) return (<div></div>)
    const { content, defaultValue, showFieldName, readOnly, cssPrefix } = this.state;
    let o = content;
    
    if(o.array){
      return (
        <ZReduxFormArray
          {...this.state}
          label={o.label}
          name={o.name}
          dValue={defaultValue}
          canDelete={o.canDelete}
          canAdd={o.canAdd}
          content={o.array}
          />
      );
    }else if(o.inline){    
      let rtn = _.map(o.inline, (v, x) => {
        if(x === 0){
          return (
            <div className={cssPrefix + "zrf-field-inline-field"} key={x}>
              {v && 
                <ZReduxFormInput 
                  {...this.state}
                  readOnly={readOnly}
                  content={v}
                  />
              }
            </div>
          )
        }
        return(
          <div className={cssPrefix + "zrf-field-inline-field"} key={x}>
            <div className={cssPrefix + "zrf-field-inner"}>
              {v.label}
            </div>
            <ZReduxFormInput
              {...this.state}
              key={x}
              content={v}
              dValue={defaultValue[v.name]}
              />
          </div>
        );
      });
      return (
        <div className={cssPrefix + "zrf-field"}>
          <div className={cssPrefix + "zrf-field-title"}>
            {o.inline && o.inline[0].label}
          </div>
          <div className={cssPrefix + "zrf-field-inline"}>
            {rtn}
          </div>
        </div>
      );
    }else if(o.format === "header"){

      return ([
        <div className={cssPrefix + "zrf-header"} key={"header"}>
          {o.label}
        </div>,
        <div key="end"></div>
      ]);

    }else if(defaultValue && (o.format === "radio" || o.format === "checkbox")){
      if(showFieldName){
        return (
          <ZReduxFormField
            {...this.state}
            content={o}
            dValue={defaultValue[o.name]}
            />
        )
      }else{
        return(
          <ZReduxFormInput
            {...this.state}
            readOnly={readOnly}
            content={o}
            dValue={defaultValue[o.name]}
            />
        );
      }
    }else{
      if(showFieldName){
        return (
          <ZReduxFormField
            {...this.state}
            content={o}
            />
        )
      }else{
        return(
          <ZReduxFormInput
            {...this.state}
            readOnly={readOnly}
            content={o}
            />
        );
      }
    }
  }
}

export default ZReduxFormRow;
