/*REACT*/
import React, { Component } from 'react';

/*BASE*/

/*CSS*/
import './ZTablizar.css';

import ZTHeaderTr from './ZTHeaderTr';

class ZTHeader extends Component {

  constructor(){
    super();
    this.state = {}
  }

  componentDidMount(){
    this.setState((state, props) => ({
      ...props
    }));
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps !== this.props){
      this.componentDidMount();
    }
  }

  render() {
    return(
      <thead>
        <ZTHeaderTr
          {...this.state}
          />
      </thead>
    );
  }

}

export default ZTHeader;