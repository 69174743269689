/*REACT*/
import React, { Component } from 'react';
import PropsType from 'prop-types';

/*BASE*/
import _ from 'lodash';
import ZReduxFormRow from './ZReduxFormRow';

/*CSS*/
import './ZReduxForm.css';

class ZReduxFormArray extends Component {

  static propTypes = {
    cssPrefix: PropsType.string,
    content: PropsType.array.isRequired,
    name: PropsType.string.isRequired,
    label: PropsType.string,
    canAdd: PropsType.bool,
    canDelete: PropsType.bool
  }

  static defaultProps = {
    cssPrefix: '',
    label: '',
    canAdd: false,
    canDelete: false
  };

  constructor(){
    super();
    this.refs = [];
    this.state = {
      length: 0
    };
  }

  componentDidMount(){
    this.setAllStates(()=>{
      let {data, name, getByAccessor, forcelength} = this.state;
      if(!data) return;
      let obj = getByAccessor(data, name);
      this.setState({
        length: obj ? obj.length : (forcelength? forcelength: 0)
      })
    });
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.data !== this.props.data){
      this.componentDidMount()
    }

  }

  setAllStates = (callback) => {
    this.setState((state, props) => ({
      ...props
    }), () => {
      if(callback) callback();
    });
  }

  deleteRow = (row) => {
    let {content, name, deleteField} = this.state;

    _.map(content, (o, i) => {
      let fieldName;
      if(o.name.length === 0){
        fieldName = name + '.' + row;
      }else{
        fieldName = name + '.' + row + '.' + o.name;
      }
      deleteField(fieldName);
    });
    
    this.setState((state, props) => ({
      length: state.length - 1
    }));
    
  }

  addRow = () => {
    console.log("Add");
    this.setState((state, props) => ({
      length: state.length + 1
    }));
  }

  renderAdd = () => {
    let {cssPrefix} = this.state;
    return (
      <tr className={cssPrefix + "zrf-array-add-row"}>
        {this.renderAddFields()}
      </tr>
    );
  }

  renderAddFields = () => {
    let {content, name, length, cssPrefix, deleteField, getByAccessor} = this.state;
    let rtn = [];

    rtn = _.map(content, (obj, i) => {
      let o = Object.assign({}, obj);
      let newName;
      if(obj.name.length === 0){
        newName = name + '.' + length;
      }else{
        newName = name + '.' + length + '.' + obj.name;
      }
      
      o.name = newName;
      return(
        <td key={i}>
          <ZReduxFormRow
            {...this.state}
            name={o.name}
            content={o}
            getByAccessor={getByAccessor}
            deleteField={deleteField}
            showFieldName={false}
            readOnly={true}
            />
        </td>
      );
    });

    rtn = [...rtn, 
    <td key="end">
      <div className={cssPrefix + "zrf-cellBtn"} onClick={() => this.addRow()}>
        <i className="fas fa-plus"/>
      </div>
    </td>
    ];

    return rtn;
  }

  renderHeader = () => {
    let {content, cssPrefix} = this.state;
    let rtn = _.map(content, (o, i) => {
      return(
        <th className={cssPrefix + "zrf-array-header"} key={i}>
          {o.label}
        </th>
      );
    });

    rtn = [...rtn, <th key="end"></th>];

    return rtn;
  }

  renderData = () => {
    let {length} = this.state;
    return _.map(_.range(length), (o, i) => {
      return(
        <tr key={i}>
          {this.renderFields(i)}
        </tr>
      );
    })
  }

  renderFields = (row) => {
    let {content, name, canDelete, readOnly, cssPrefix, deleteField, defaultValue, getByAccessor} = this.state;
    let rtnRow = _.map(content, (obj, i) => {
      let o = Object.assign({}, obj);
      let newName;
      if(obj.name.length === 0){
        newName = name + '.' + row;
      }else{
        newName = name + '.' + row + '.' + obj.name;
      }
      o.name = newName;
      return (
        <td key={i}>
          <ZReduxFormRow
            {...this.state}
            content={o}
            getByAccessor={getByAccessor}
            deleteField={deleteField}
            defaultValue={defaultValue}
            showFieldName={false}
            />
        </td>
      );
    });

    rtnRow = [...rtnRow, canDelete && !readOnly? 
      <td key="end">
        <div className={cssPrefix + "zrf-cellBtn"} onClick={() => this.deleteRow(row)}>
          <i className="fas fa-times"/>
        </div>
      </td>
      :
      <td key="end"></td>];
      
    return rtnRow;
  }

  renderTable = () => {
    let {cssPrefix, canAdd, readOnly} = this.state;
    return(
      <table className={cssPrefix + "zrf-array-table"}>
        <thead>
          <tr className={cssPrefix + "zrf-array-header-row"}>
            {this.renderHeader()}
          </tr>
        </thead>
        <tbody>
        {this.renderData()}
        {canAdd && !readOnly && this.renderAdd()}
        </tbody>
      </table> 
    );
  }

  render() {
    let {cssPrefix, canAdd, label, length} = this.state;
    if(canAdd || length > 0){
      return (
        <div className={cssPrefix + "zrf-array-box"}>
          <div className={cssPrefix + "zrf-array-label"}>
            {label}
          </div>
          {this.renderTable()}
        </div> 
      )
    }
    return (
      <div></div>   
    );
  }
}

export default ZReduxFormArray;
