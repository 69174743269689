import React, { Component } from 'react';
import PropsType from 'prop-types';
import htmlParser from 'html-react-parser';
import ZTagParser from './ZCMHTMLTags/ZTagParser';
import ZTablizar from '../../../_Utilities/ZTablizar/ZTablizar';

import _ from 'lodash';

import "./ZCMsg.css";

class ZCMTable extends Component {

  static propTypes = {
    text: PropsType.string,
  }

  static defaultProps = {
    text: ""
  }

  constructor(){
    super();
    this.state = {
      hide: true
    };
  }

  componentDidMount(){
    this.setState((state, props) => ({ 
      ...props
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat){
    if(prevStat !== nextProps){
      return {
        ...nextProps
      }
    }
  }

  renderTable = (table) => {
    let {cssPrefix} = this.state;
    return ( 
      <ZTablizar
        cssPrefix={cssPrefix}
        pagination={false}
        serverSidePagination={false}
        showIndicator={false}
        noDataText={"No Data Available."}
        showSelector={false}

        schema={table.columns}
        data={table.rows}
        defaultPageSize={table.rows.length}
        totalEntries={table.rows.length}
        minRow={1}
        />
    );
  }

  render(){
    let { cssPrefix, table } = this.state;
    return (
      <div className={cssPrefix + " zchat-msg-text"}>
        {this.renderTable(table)}
      </div>
    )
  }
}

export default ZCMTable;