const Translate = {
  connecting: {
    en: "Connecting",
    tc: "連接中",
    sc: "连接中",
  },
  connectFail: {
    en: "Cannot connect to server.",
    tc: "連接伺服器失敗。",
  },
  virturalAssistant: {
    en: "Hongkong Post Service Ambassador",
    tc: "香港郵政客服小助手",
    sc: "香港邮政客服小助手",
  },
  changeLangIcon: {
    en: "Icon/en.png",
    tc: "Icon/tc.png",
  },
  surveyTitle: {
    en: "We would appreciate it if you would fill in our survey to make your next visit a better one!",
    tc: "請提供您的寶貴意見，讓我們的服務不斷改進。",
  },
  yesAns: {
    en: "Continue",
    tc: "繼續",
  },
  noAns: {
    en: "Exit",
    tc: "離開",
  },
  back: {
    en: "Back",
    tc: "返回",
  },
  others: {
    en: "Others",
    tc: "其他",
  },
  starTitle: {
    en: "How satisfied are you with the service provided? (10-highest and 1-lowest)",
    tc: "請為是次服務評分：（ 10分為最高， 1分為最低）",
  },
  detailAnswer: {
    en: "Please specify the catgory(s)of the information:",
    tc: "請問該資料屬於以下哪一個類別？(可選多於一項)",
  },
  submit: {
    en: "Submit",
    tc: "提交",
  },
  cancel: {
    en: "Cancel",
    tc: "取消",
  },
  surveyEnding: {
    en: "Thank you for using our Virtual Assistant “Tinny”. <br>We look forward to seeing you again! <br><br>Goodbye!",
    tc: "多謝您使用煤氣公司的虛擬助理服務。<br>希望在未來的日子，我們的虛擬助理“Tinny”能繼續為您服務。<br><br>再見！",
  },
  reasonTitle: {
    en: "Do you have any opinion(s)?",
    tc: "請問您有什麼意見？（可選多於一項）",
  },
  triggerLiveAgent: {
    en: "Contact to Live Agent",
    tc: "即時與在線客服聯絡",
  },
  triggereService: {
    en: "e-Form",
    tc: "網上查詢表格",
  },
  alertMsgOthText: {
    en: "Please fill in the 'Others' comment.",
    tc: "請填寫其他意見。",
  },
  alertMsgReasonCheck: {
    en: "Please select at least one option.",
    tc: "請選擇至少一個選項。",
  },
  liveAgent: {
    Title: {
      en: "Please let me know your enquiry.<br/>I will transfer the case to Customer Service Officer <br/>to solve the problem!",
      tc: "請問您想查詢以下哪個項目？<br/>稍後我會轉達客戶服務主任，<br/>方便佢幫手解決問題 !",
    },
    SubTitle: {
      en: "Please select the following button:",
      tc: "請選擇以下按鈕：",
    },
  },
  blockMsg: {
    en: "You have too many queries in a short time, <br>this page will temporarily stop the service.",
    tc: "你在短時間內查詢次數過多，<br>此頁面將暫時停止服務。",
  },
  inputField: {
    textPlaceholder: {
      tc: "請輸入回覆 ...",
      en: "Please input something here ...",
      sc: "请输入回覆 ...",
    },
  },
  sessionTimeoutText: {
    tc: '對不起，對話已閒置一段時間，你與客服小助手的對話已經結束。請按"繼續"重新開始對話。 ',
    sc: '对不起，对话已闲置一段时间，你与客服小助手的对话已经结束。请按"继续"重新开始对话。 ',
    en: 'Sorry! The conversation has been idled for some time, your session with the Service Ambassador is ended. Please press  click "Continue" to start a new session.',
  },
  sessionTimeoutConfirmText: {
    tc: "繼續",
    sc: "继续",
    en: "Continue",
  },
};

export default Translate;
