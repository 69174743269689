/*REACT*/
import React, { Component } from 'react';

/*BASE*/

/*CSS*/
import './ZTablizar.css';

class ZTBodyTd extends Component {

  constructor(){
    super();
    this.state = {}
  }

  componentDidMount(){
    this.setState((state, props) => ({
      ...props
    }));
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps !== this.props){
      this.componentDidMount();
    }
  }  

  render() {
    let {key, cssPrefix, secure, hidePassword, content, cssClass} = this.state;
    return(
      <td key={key} className={cssPrefix + " " + (cssClass? cssClass :"zt-td ") + (secure && hidePassword ? "hidden" : "")}>
        {content? content : <div>&nbsp;</div>}
      </td>
    );
  }
}

export default ZTBodyTd;