import {CONSOLE_LOG, CONSOLE_POP} from '../_base/actions';

const INIT_STATE = {
  logs: []
}

export default (state = INIT_STATE, action) => {
  switch(action.type){
    case CONSOLE_LOG:
    return {...state, logs: [...state.logs, action.payload] }
    case CONSOLE_POP:
    return {...state, logs: state.logs.slice(1, state.logs.length)}
    default:
      return state;
  }
}