import React, { Component } from 'react';
import PropsType from 'prop-types';

import ZCTouch from '../ZCTouch';
import "./ZCMsg.css";

class ZCMHeader extends Component {

  static propTypes = {
    sender: PropsType.object,
  }

  static defaultProps = {
    sender: {},
  }

  constructor(){
    super();
    this.state = {};
  }

  componentDidMount(){
    this.setState((state, props) => ({ 
      ...props
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat){
    if(prevStat !== nextProps){
      return {
        ...nextProps
      }
    }
  }

  render(){
    let { cssPrefix, sender, onHeaderClick } = this.state;
    return (
      <ZCTouch onPress={()=>onHeaderClick(sender)}>
        <div className={cssPrefix + " zchat-msg-header"}>
          {sender.name}
        </div>
      </ZCTouch>
    )
  }
}

export default ZCMHeader;