import axios from 'axios';
import * as config from './config';

export const DOMAIN = process.env.NODE_ENV === 'production' ?
config.domain[process.env.REACT_APP_STAGE]: config.domain.test;

export const LOCALE_UPDATE = 'LOCALE_UPDATE';
export const CONSOLE_LOG = 'CONSOLE_LOG';
export const CONSOLE_POP = 'CONSOLE_POP';
export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_SIGNOUT = 'AUTH_SIGNOUT';

export const _changeLang = (e) => async(dispatch) => {
  localStorage.setItem("locale", JSON.stringify(e));
  dispatch({
    type: LOCALE_UPDATE, payload: e
  });
}

export const _console = (e) => async (dispatch) => {
  dispatch({
    type: CONSOLE_LOG, payload: e
  });
}

export const _consolePop = () => async (dispatch) => {
  dispatch({
    type: CONSOLE_POP
  });
}

export const _signIn = (payload, onSuccess = null, onFail = null) => async (dispatch) => {
  console.log("_signIn");
  let url = DOMAIN + '/Base/Authorize/SignIn';

  try {
    let res = await axios.post(url, payload);
    console.log("_signIn", res.data);
    if(res.data.Success === true){
      localStorage.setItem("User", JSON.stringify(res.data.payload));
      dispatch({
        type: AUTH_SIGNIN, payload: res.data.payload
      });
      if(onSuccess != null) onSuccess();
    }else{
      if(onFail != null) onFail(res.data);
    }
  }catch(e){
    if(onFail != null) onFail(e);
  }
}

export const _signOut = (onSuccess = null, onFail = null) => async (dispatch) => {
  console.log("_signOut");
  localStorage.removeItem("User");
  dispatch({
    type: AUTH_SIGNOUT
  });
  if(onSuccess != null) onSuccess();
}
