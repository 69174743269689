import React, { Component } from "react";
import PropsType from "prop-types";

import ZCMSysMsg from "./ZCMSysMsg";
import ZCTouch from "../ZCTouch";
import ZCMText from "./ZCMText";
import ZCMImage from "./ZCMImage";
import ZCMButtons from "./ZCMButtons";
import ZCMImgButtons from "./ZCMImgButtons";
import ZCMVideo from "./ZCMVideo";
import ZCMTemplates from "./ZCMTemplates";
import ZCMTable from "./ZCMTable";

import ZCMFundTemplates from "./HKPost/ZCMFundTemplates";

import ZCMHeader from "./ZCMHeader";
import ZCMFooter from "./ZCMFooter";

import "./ZCMsg.css";

class ZCMsg extends Component {
  static propTypes = {
    _onQuickReply: PropsType.func,
    pos: PropsType.oneOf(["in", "out"]),
    item: PropsType.object,
    last: PropsType.bool,
    typingAni: PropsType.bool,
  };

  static defaultProps = {
    _onQuickReply: () => {},
    pos: "in",
    item: { msg: { text: "" } },
    last: false,
    typingAni: false,
  };

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.setState((state, props) => ({
      ...props,
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat) {
    if (prevStat !== nextProps) {
      return {
        ...nextProps,
      };
    }
  }

  renderHeader() {
    let { showHeader, pos, typingAni } = this.state;
    let { user } = this.state.item;

    if (pos !== "in") return;
    if (!showHeader || typingAni) return;

    return <ZCMHeader {...this.state} sender={user} />;
  }

  renderMsg() {
    let { showQuickRepliesAsButtons, disableButtonsAfterSend, disableTemplateButtonsAfterSend, last, cssPrefix } = this.state;
    let { msg, addOns } = this.state.item;

    let rendered = [];

    if (msg.title) {
      rendered.push(<ZCMText {...this.state} key={"title"} text={msg.title} iaddOns={addOns} />);
    }

    if (msg.table) {
      rendered.push(<ZCMTable {...this.state} key={"table"} table={msg.table} iaddOns={addOns} />);
    }

    if (msg.fundtemplates) {
      rendered.push(<ZCMFundTemplates {...this.state} key={"fundtemplate"} templates={msg.fundtemplates} disabled={disableTemplateButtonsAfterSend && !last} iaddOns={addOns} />);
    }

    if (msg.image) {
      rendered.push(<ZCMImage {...this.state} key={"image"} src={msg.image} iaddOns={addOns} />);
    }

    if (msg.video) {
      rendered.push(<ZCMVideo {...this.state} key={"video"} video={msg.video} iaddOns={addOns} />);
    }

    if (msg.text) {
      rendered.push(<ZCMText {...this.state} key={"text"} text={msg.text} iaddOns={addOns} />);
    }

    if (msg.quickReplies) {
      if (showQuickRepliesAsButtons) {
        rendered.push(<ZCMButtons {...this.state} key={"quickreplies"} buttons={msg.quickReplies} disabled={disableButtonsAfterSend && !last} iaddOns={addOns} />);
      }
    }

    if (msg.buttons) {
      rendered.push(<ZCMButtons {...this.state} key={"buttons"} buttons={msg.buttons} disabled={disableButtonsAfterSend && !last} iaddOns={addOns} />);
    }

    if (msg.imgbuttons) {
      rendered.push(<ZCMImgButtons {...this.state} key={"imgbuttons"} buttons={msg.imgbuttons} disabled={disableButtonsAfterSend && !last} iaddOns={addOns} />);
    }

    if (msg.templates) {
      rendered.push(<ZCMTemplates {...this.state} key={"template"} templates={msg.templates} disabled={disableTemplateButtonsAfterSend && !last} iaddOns={addOns} />);
    }
    if (msg.loading) {
      rendered.push(
        <div className={cssPrefix + " zcm-loading"} key={"loading"}>
          <div className={cssPrefix + " zcm-loading-circle one"} />
          <div className={cssPrefix + " zcm-loading-circle two"} />
          <div className={cssPrefix + " zcm-loading-circle three"} />
        </div>
      );
    }

    return rendered;
  }

  renderFooter() {
    let { showFooter, typingAni } = this.state;
    let { createdAt, status, lapseTime } = this.state.item;
    if (!showFooter || typingAni) return;

    return <ZCMFooter {...this.state} createdAt={createdAt} status={status} lapseTime={lapseTime} />;
  }

  _onMsgPress = (_id) => {};

  _onMsgLongPress = (_id) => {};

  render() {
    let { cssPrefix, onMsgPress, onMsgLongPress, pos, canClickOnIn, canClickOnOut } = this.state;
    let { _id, msg } = this.state.item;

    if (msg.system) {
      return <ZCMSysMsg {...this.state} key={_id} text={msg.system} />;
    }

    let disabled = !((pos === "in" && canClickOnIn) || (pos === "out" && canClickOnOut));

    return (
      <ZCTouch {...this.state} key={_id} onPress={() => onMsgPress(_id)} onLongPress={() => onMsgLongPress(_id)} disabled={disabled}>
        <div className={cssPrefix + " zchat-bubble " + pos}>
          {this.renderHeader()}
          {this.renderMsg()}
          {this.renderFooter()}
        </div>
      </ZCTouch>
    );
  }
}

export default ZCMsg;
