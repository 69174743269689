import React, { Component } from "react";

import htmlParser from "html-react-parser";
import ZCTouch from "../../ZCTouch";
import PropsType from "prop-types";
import _ from "lodash";

import ZTagParser from "../ZCMHTMLTags/ZTagParser";
import ZCScroll from "../../ZCScroll";

import "../ZCMsg.css";
import "./ZCMFundTemplates.css";

class ZCMFundTemplates extends Component {
    static propTypes = {
        templates: PropsType.array,
        disabled: PropsType.bool,
    };

    static defaultProps = {
        templates: [],
        disabled: true,
    };

    constructor() {
        super();
        this.state = {
            page: 0,
        };
        this.tps = [];
    }

    componentDidMount() {
        this.setState((state, props) => ({
            ...props,
        }));
    }

    static getDerivedStateFromProps(nextProps, prevStat) {
        if (prevStat !== nextProps) {
            return {
                ...nextProps,
            };
        }
    }

    _scrollIntoView(id, animated = true) {
        if (this.tps === null || !this.tps[id]) {
            return;
        }
        this.tps[id].scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
        });
    }

    onMountScroll = (callbacks) => {
        this.MountScroll = callbacks;
    };

    pageShift = (x) => {
        this.setState(
            (stat, props) => ({
                page: Math.max(0, Math.min(stat.page + x, stat.templates.length - 1)),
            }),
            () => {
                this._scrollIntoView(this.state.page);
            }
        );
    };

    renderTpButtons(tclassname, buttons) {
        let { cssPrefix, _onQuickReply, onPhoneClick, onWebClick, disabled } = this.state;

        let rendered = [];
        _.map(buttons, (o, i) => {
            let disabledBtn = disabled || o.payload === "";
            let func = null;
            let last = i === buttons.length - 1;
            switch (o.type) {
                case "web":
                    func = () => onWebClick(o.payload);
                    break;
                case "phone":
                    func = () => onPhoneClick(o.payload);
                    break;
                default:
                    func = () => _onQuickReply(o);
                    break;
            }

            rendered.push(
                <div key={i} className={tclassname + "-btn " + (disabledBtn ? "disabled " : "") + (last ? "last " : "")}>
                    <ZCTouch cssPrefix={cssPrefix} onPress={func} disabled={disabledBtn}>
                        <div className={tclassname + "-btn-text " + (disabledBtn ? "disabled " : "") + (last ? "last " : "")}>{o.title}</div>
                    </ZCTouch>
                </div>
            );
        });

        return rendered;
    }

    renderTemplates() {
        let { cssPrefix, templates, HTMLEnabled, HTMLSpecialTagParser, iaddOns } = this.state;
        let rendered = [];
        let tclassname = cssPrefix + " fund zchat-msg-tp";
        _.map(templates, (o, i) => {
            let text = "";
            if (o.text) {
                if (HTMLEnabled) {
                    let parsed = htmlParser(o.text);
                    if (HTMLSpecialTagParser) {
                        text = HTMLSpecialTagParser(cssPrefix, parsed, iaddOns);
                    } else {
                        text = ZTagParser.Parse(cssPrefix, parsed, iaddOns);
                    }
                } else {
                    text = o.text;
                }
            }

            rendered.push(
                <div key={i} className={tclassname} ref={(e) => (this.tps[i] = e)}>
                    <div className={tclassname + "-title-border"}>
                        <div className={tclassname + "-title"}>{o.ShareClass}</div>
                    </div>
                    <div className={tclassname + "-subtitle-border"}>
                        <div className={tclassname + "-subtitle"}>{o.subtitle}</div>
                    </div>
                    <div className={tclassname + "-dailychange-border"}>
                        <div className={tclassname + "-dailychange"}>
                            <div className={tclassname + "-dailychange-title"}>Daily Change</div>
                            <div className={tclassname + "-dailychange-value" + (o.DailyChange > 0 ? " green" : " red")}>{(o.DailyChange > 0 ? "+" : "") + o.DailyChange + "%"}</div>
                            <div className={tclassname + "-dailychange-detail"}>
                                <div className={tclassname + "-dailychange-row"}>
                                    <div className={tclassname + "-dailychange-row-title"}>Dealing Date</div>
                                    <div className={tclassname + "-dailychange-row-value"}>{o.DealingDate}</div>
                                </div>
                                <div className={tclassname + "-dailychange-row"}>
                                    <div className={tclassname + "-dailychange-row-title"}>NAV</div>
                                    <div className={tclassname + "-dailychange-row-value"}>{o.NAV}</div>
                                </div>
                                <div className={tclassname + "-dailychange-row"}>
                                    <div className={tclassname + "-dailychange-row-title"}>Currency</div>
                                    <div className={tclassname + "-dailychange-row-value"}>{o.Currency}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {o.Reports &&
                        this.renderTpButtons(tclassname, [
                            {
                                title: "Download Dividend Report",
                                payload: o.Reports && o.Reports.Dividend && o.Reports.Dividend.path,
                                type: "web",
                            },
                        ])}
                </div>
            );
        });

        return rendered;
    }

    render() {
        let { cssPrefix, templates } = this.state;
        return (
            <div className={cssPrefix + " zchat-msg-tcontainer"}>
                {templates.length > 1 && (
                    <div key={"left"} className={cssPrefix + " zchat-msg-tcontainer-arrows"}>
                        <ZCTouch cssPrefix={cssPrefix} onPress={() => this.pageShift(-1)}>
                            <i className='fas fa-chevron-left' />
                        </ZCTouch>
                    </div>
                )}
                <ZCScroll cssPrefix={cssPrefix + " zchat-msg-tlist"} onMounted={this.onMountScroll}>
                    {this.renderTemplates()}
                </ZCScroll>
                {templates.length > 1 && (
                    <div key={"right"} className={cssPrefix + " zchat-msg-tcontainer-arrows"}>
                        <ZCTouch cssPrefix={cssPrefix} onPress={() => this.pageShift(1)}>
                            <i className='fas fa-chevron-right' />
                        </ZCTouch>
                    </div>
                )}
            </div>
        );
    }
}

export default ZCMFundTemplates;
