/*REACT*/
import React, { Component } from 'react';

/*BASE*/
import _ from 'lodash';

/*CSS*/
import './ZTablizar.css';

import ZTHeaderTh from './ZTHeaderTh';

class ZTHeaderTr extends Component {

  constructor(){
    super();
    this.state = {}
  }

  componentDidMount(){
    this.setState((state, props) => ({
      ...props
    }));
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps !== this.props){
      this.componentDidMount();
    }
  }

  renderCell = () => {
    let dataCells = [], inlineBtnCells = [], selectorCell = [];
    let cellNum = 0;
    let {schema, inlineButtons, restricted, GetPass, inlineButtonsAlign} = this.state;

    if(this.state.showSelector){
      cellNum++;
      selectorCell = [
        <ZTHeaderTh
          {...this.state}
          content={<input type="checkbox" onChange={(e) => this.state.onSelectAllRecords(e.target.checked)}/>}
          key={cellNum}
          />
      ]
    }

    dataCells = _.map(schema, (o, i) => {
      cellNum++;
      return (
        <ZTHeaderTh
          {...this.state}
          content={o.Header || o.header}
          sortable={o.sortable}
          field={i}
          key={cellNum}
          />
      );
    });

    inlineBtnCells = _.map(inlineButtons, (o, i) => {
      if(restricted && !_.isEmpty(o.restricted) && !GetPass(o.restricted)) return;
      cellNum++;
      return (
        <ZTHeaderTh
          {...this.state}
          content={null}
          key={cellNum}
          />
      );
    });

    if(inlineButtonsAlign === 'left'){
      return [...selectorCell, ...inlineBtnCells, ...dataCells];
    }

    return [...selectorCell, ...dataCells, ...inlineBtnCells];
  }

  render() {
    let {cssPrefix} = this.state;
    return(
      <tr className={cssPrefix + "zt-htr"}>
        {this.renderCell()}
      </tr>
    );
  }

}

export default ZTHeaderTr;