import React, { Component } from 'react';
import ZCTouch from '../ZCTouch';
import ZCScroll from '../ZCScroll';

import _ from 'lodash';

import "./ZCQReplies.css";

class ZCQReplies extends Component {

  constructor(){
    super();
    this.state = {};
  }

  componentDidMount(){
    this.setState((state, props) => ({ 
      ...props
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat){
    if(prevStat !== nextProps){
      return {
        ...nextProps
      }
    }
  }

  onMountScroll = (callbacks) => {
    this.MountScroll = callbacks;
  }

  scrollLeft = () => {
    this.MountScroll.scroll(-50);
  }

  scrollRight = () => {
    this.MountScroll.scroll(50);
  }

  renderButtons(){
    let {cssPrefix, quickReplies, _onQuickReply, disabled} = this.state;
    let rendered = [];
    _.map(quickReplies, (o, i) => {
      rendered.push(
        <div key={i} className={cssPrefix + " zchat-quickreplybar-btn " + (disabled? "disabled" : "")}>
          <ZCTouch cssPrefix={cssPrefix} onPress={() => _onQuickReply(o)} disabled={disabled} stopPropagation={false}>
            <div className={cssPrefix + " zchat-quickreplybar-btn-text " + (disabled? "disabled" : "")}>
              {o.title}
            </div>
          </ZCTouch>
        </div>
      );
    })

    return rendered;

  }

  render(){
    let {cssPrefix} = this.state;

    return (
      <div className={cssPrefix + " zchat-quickreplybar"}>
        <div key={"left"} className={cssPrefix + " zchat-quickreplybar-arrows"}>
          <ZCTouch cssPrefix={cssPrefix} onHold={() => this.scrollLeft()} forceLongPress={false}>
            <i className="fas fa-chevron-left fa-lg"/>
          </ZCTouch>
        </div>
        <ZCScroll cssPrefix={cssPrefix + " zchat-msg-qrlist"} onMounted={this.onMountScroll}>
          {this.renderButtons()}
        </ZCScroll>
        <div key={"right"} className={cssPrefix + " zchat-quickreplybar-arrows"}>
          <ZCTouch cssPrefix={cssPrefix} onHold={() => this.scrollRight()} forceLongPress={false}>
            <i className="fas fa-chevron-right fa-lg"/>
          </ZCTouch>
        </div>
      </div>
    )
  }
}

export default ZCQReplies;