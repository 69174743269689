/*REACT*/
import React, { Component } from 'react';
import PropsType from 'prop-types';
import { reduxForm, change } from 'redux-form';

/*BASE*/
import _ from 'lodash';

/*CSS*/
import './ZReduxForm.css';

import ZReduxFormRow from './ZReduxFormRow';
import moment from 'moment';

class ZReduxForm extends Component {

  /*
  schema format:
  [
    //simple
    {
      label: string,
      name: string,
      format: text | file | dropdown | date | datetime | textarea | display | header
      validate?: array, ["required" | "email" | "number"]
      selectRef?: string, // for dropdown ref origin
      selectCap?: string, // for dropdown cap accessor
      selectVal?: string, // for dropdown val accessor
      readOnly?: bool | false,
      min?: number,
      max?: number,
      accept?: string, // for upload file
      rows?: number, // textarea rows
      cols?: number, // textarea cols
      resizeable?: bool, // textarea resizeable
      Cell?: function (data, field-data, state)  
    },

    //array (include name array number) e.g. accessor.n.value
    {
      label: string,
      name: '' | string, //if empty, the accessor will only call accessor.n
      canAdd?: bool | false,
      canDelete?: bool | false,
      array: [
        {
          //simple
          name: '' | string, //if empty, the accessor will only call accessor.n
        }
      ]
    },

    //inline
    {
      inline: [
        {
          //simple
        },
        {
          //simple
        }
      ]
    }
  ]

  */

  static propTypes = {
    cssPrefix: PropsType.string,
    onSubmit: PropsType.func,
    onCancel: PropsType.func,
    onRevert: PropsType.func,
    buttons: PropsType.array,
    title: PropsType.string,
    readOnly: PropsType.bool,
    form: PropsType.string.isRequired,
    schema: PropsType.array.isRequired,
    addOns: PropsType.object,
    noBox: PropsType.bool
  }

  static defaultProps = {
    cssPrefix: '',
    onSubmit: () => {},
    onCancel: () => {},
    onRevert: () => {},
    buttons: ["Cancel", "Clear", "Submit"],
    title: '',
    readOnly: false,
    addOns: {},
    noBox: false
  };

  constructor(){
    super();
    this.state = {
      data: {}
    };
  }

  componentDidMount(){
    this.setAllStates(()=>{
      if(this.state.defaultValue){
        this.fillTheForm(this.state.defaultValue);
      }
    });
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps !== this.props){
      this.setAllStates();
    }

    if(prevProps.defaultValue !== this.props.defaultValue){
      if(this.props.defaultValue){
        this.fillTheForm(this.props.defaultValue);
      }
    }
  }

  setAllStates = (callback) => {
    this.setState((state, props) => ({
      ...props
    }), callback);
  }

  getByAccessor = (obj, accessor) => {
    if(!accessor || !obj) return undefined;
    let fields = accessor.split('.');
    let rtn = obj;
  
    for(let i=0; i<fields.length; i++){
      if(_.isUndefined(rtn) || _.isNull(rtn))
        return undefined;
      rtn = rtn[fields[i]];
    }
  
    return rtn;
  }

  fillTheForm = (v) => {
    console.log("Form filled", v);
    this.setState({
      data: v
    });
    let {form, schema} = this.state;
    let str = "";
    return _.map(schema, (o, i) => {
      if(o.inline){
        _.map(o.inline, (w, x) => {
          this.Dispatch(v, w, form, str);
        });
      }else{
        this.Dispatch(v, o, form, str);
      }
    });
  }

  Dispatch(v, o, form, str){
    if(o.name === undefined) return;
    let {dispatch} = this.props;
    switch(o.format){
      case 'date':
      let d = this.getByAccessor(v, o.name);
      str = d? moment(d).format('YYYY-MM-DD') : "";
      if(str !== "0000-00-00"){
        dispatch(change(form, o.name, str)); 
      }
      break;
      case 'datetime':
      let dt = this.getByAccessor(v, o.name);
      str = dt? moment(dt).format('YYYY-MM-DD HH:mm:ss') : "";
      if(str !== "0000-00-00 00:00:00"){
        dispatch(change(form, o.name, str)); 
      }
      break;
      case 'radio':
        dispatch(change(form, o.name, this.getByAccessor(v, o.name)));
      break;
      case 'check':

      break;
      default: 
      let value = this.getByAccessor(v, o.name);
      dispatch(change(form, o.name, value)); 
      break;
    } 
  }

  deleteField = (fieldName) => {
    let {form} = this.state;
    let {dispatch} = this.props;
    // I don't know why it works
    console.log("Delete " + fieldName);
    dispatch(change(form, fieldName, ""));
  }

  onSubmit = async formProps => {
    console.log("formSubmit");
    this.state.onSubmit(formProps, this.state.defaultValue);
    return false;
  }

  onCancel = () => {
    console.log("formCancel");
    this.state.onCancel();
  }

  renderForm = () => {
    let {cssPrefix, title, schema, noBox} = this.state;
    return (
      <div className={cssPrefix + "zrf-form"}>
        <div className={cssPrefix + "zrf-title"}>
          {title}
        </div>
        {
          schema && schema.length > 0 &&
          <div className={cssPrefix + "zrf-box" + (noBox? " no-box" : "")}>
            {this.renderRows()}
          </div>
        }
        
        {this.props.children}
        <div className={cssPrefix + "zrf-btnrow"}>  
          {this.renderButtons()}
        </div>
      </div>
    );
  }

  renderRows = () => {
    let {schema, defaultValue} = this.state;
    return _.map(schema, (o, i) => {
      return (
        <ZReduxFormRow
          {...this.state}
          key={i}
          content={o}
          getByAccessor={this.getByAccessor}
          deleteField={this.deleteField}
          defaultValue={defaultValue}
          showFieldName={true}
          />
      )
    });
  }

  renderButtons = () => {
    let {cssPrefix, submitting, pristine, reset, buttons, noBox} = this.state;
    let classname = cssPrefix + "zrf-btn" + (noBox ? " no-box": "");
    let buttonsJSX = {
      "OK":
      <button type="button" className={classname + " ok"} disabled={submitting} onClick={this.onCancel} key={0}>
        <i className="fas fa-check"/> OK
      </button>,
      "Submit": 
      <button type="submit" className={classname + " submit"} disabled={submitting} key={0}>
        <i className="fas fa-paper-plane"/> Submit
      </button>,
      "Cancel":
      <button type="button" className={classname + " cancel"} disabled={submitting} onClick={this.onCancel} key={1}>
        <i className="fas fa-ban"/> Cancel
      </button>,
      "Clear":
      <button type="button" className={classname + " clear"} disabled={pristine || submitting} onClick={reset} key={2}>
        <i className="fas fa-undo"/> Clear
      </button>,
      "Revert":
      <button type="button" className={classname + " revert"} disabled={submitting} onClick={this.onRevert} key={3}>
        <i className="fas fa-history"/> Revert
      </button>,
      "SignIn": 
      <button type="submit" className={classname + " login"} disabled={submitting} key={0}>
        <i className="fas fa-sign-in-alt"/> Sign In
      </button>,
      "SignOut": 
      <button type="submit" className={classname + " logout"} disabled={submitting} key={0}>
        <i className="fas fa-sign-out-alt"/> Sign Out
      </button>
    }

    return _.map(buttons, (o, i) => {
      return buttonsJSX[o];
    });
  }

  

  render() {
    const { cssPrefix } = this.state;
    const {handleSubmit} = this.props;
    return (
      <div className={cssPrefix + "zreduxform"}>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          {this.renderForm()}
        </form>
      </div>
    );
  }
}

export default reduxForm()(ZReduxForm);
